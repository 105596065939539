.bg {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 300% 300%;
    background-image: linear-gradient(
          -45deg, 
          rgb(19, 119, 166) 0%, 
          rgb(51, 79, 220) 25%, 
          rgba(152,68,183,1) 51%, 
          rgb(192, 48, 101) 100%
    );  
    animation: AnimateBG 20s ease infinite;
  }
  
  @keyframes AnimateBG { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }

.landingBg {
  width: 100%;
  background-size: 150% 150%;
  background-image: linear-gradient(
        -1deg, 
        rgb(19, 119, 166) 0%, 
        rgb(51, 79, 220) 25%, 
        rgba(152,68,183,1) 71%, 
        rgb(117, 29, 133) 85%
  );  
  animation: LandingAnimateBG 20s ease infinite;
}

@keyframes LandingAnimateBG { 
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
